<template>
  <v-autocomplete
    label="Pièces"
    outlined
    hide-details
    :value="value"
    :filter="customFilter"
    :search-input.sync="search"
    :items="partList"
    :loading="loading"
    item-value="_id"
    multiple
    clearable
    @input="$emit('input', $event)"
  >
    <template v-slot:selection="{ attr, on, item, selected, select }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        close
        color="primary"
        class="pl-1 white--text"
        v-on="on"
        @click="select"
        @click:close="$emit('remove', item)"
      >
        <div
          class="rounded-pill white primary--text mr-1 px-2"
          style="padding: 2px"
        >
          {{ item.batch.number }}
        </div>
        <span v-text="`${item.serialNumber} (${item.partNumber.name})`" />
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content style="max-width: 60px">
        <div
          style="min-width:52px; max-width:52px"
          class="rounded-xl primary white--text py-1 px-2 text-center"
        >
          {{ item.batch.number }}
        </div>
      </v-list-item-content>
      <v-list-item-content>
        <v-list-item-title v-text="item.serialNumber" />
        <v-list-item-subtitle v-text="`${item.partNumber.name} (${item.partType.name})`" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import Repositories from 'src/repositories'
import { mapActions } from 'vuex'

export default {
  name: 'PartListSelection',
  components: { },
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
  },
  data: () => ({
    partList: [],
    loading: false,
    search: '',
    debounce: null,
  }),
  watch: {
    search: {
      immediate: false,
      handler (filter) {
        if (filter && filter.length > 0) {
          this.fetchEntriesDebounced(filter)
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    fetchEntriesDebounced (filter) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.fetchData(filter)
      }, 500)
    },
    async fetchData (filter) {
      try {
        this.loading = true
        this.partList = await Repositories.part.getListBySerialNumberRegex({ serialNumber: filter })
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loading = false
      }
    },
    customFilter (item, queryText) {
      const lowerQueryText = queryText.toLowerCase()
      const serialNumber = item.serialNumber.toLowerCase()

      return serialNumber.indexOf(lowerQueryText) > -1
    },
    chipText (item) {
      if (item.partNumber) return `${item.serialNumber}-${item.partNumber.name}`
      return ''
    },
  },
}
</script>
<style scoped>
.v-avatar v-list-item__avatar {
  width: 52px !important;
}
</style>
