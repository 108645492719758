<template>
  <v-row class="my-0" align="center">
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="partTypeListSelection"
        label="Type de référence(s)"
        :items="partTypeList"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="partNumberListSelection"
        label="Référence(s)"
        :items="filteredPartNumberList"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="batchListSelection"
        label="Ordre(s) de fabrication"
        :items="filteredBatchList"
        :item-text="batchItemText"
        item-value="_id"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="operationCategoryListSelection"
        label="Catégorie(s) d'opération(s)"
        :items="filteredOperationCategoryList"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="operationTypeListSelection"
        label="Opération(s)"
        :items="filteredOperationTypeList"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <part-list-selection v-model="partListSelection" @remove="removePart" />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="userListSelection"
        label="Utilisateur(s)"
        :items="userList"
        item-text="login"
        item-value="_id"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="recordTypeListSelection"
        label="Relevé(s)"
        :items="filteredRecordTypeList"
        item-text="name"
        item-value="_id"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="recordIsOk"
        label="Relevés corrects"
        :items="[
          {name: 'Vrai', value: true},
          {name: 'Faux', value: false}
        ]"
        item-value="value"
        item-text="name"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="declarationSelection"
        label="Relevés corrects"
        :items="[
          {name: 'Rebut', value: 1},
          {name: 'Dérogation', value: 2},
          {name: 'Retouche', value: 3},
          {name: 'Quarantaine', value: 4}
        ]"
        item-value="value"
        item-text="name"
        outlined
        hide-details
        chips
        deletable-chips
        small-chips
        multiple
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-menu
        v-model="fromMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="fromDateFormated"
            outlined
            label="Date de début"
            prepend-icon="edit_calendar"
            hide-details
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="fromDate"
          first-day-of-week="1"
          @input="fromMenu = false"
        />
      </v-menu>
    </v-col>
    <v-col cols="12" md="6">
      <v-menu
        v-model="toMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="toDateFormated"
            outlined
            label="Date de fin"
            prepend-icon="edit_calendar"
            hide-details
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="toDate"
          first-day-of-week="1"
          @input="toMenu = false"
        />
      </v-menu>
    </v-col>
    <v-col cols="12">
      <v-btn
        block
        color="accent"
        :disabled="!canValidate"
        @click="validate"
      >
        Valider
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import Repositories from 'src/repositories'
import PartListSelection from './PartListSelection'

export default {
  name: 'FilterSelection',
  components: { PartListSelection },
  data: () => ({
    partTypeList: [],
    partNumberList: [],
    batchList: [],
    operationTypeList: [],
    operationCategoryList: [],
    userList: [],
    recordTypeList: [],
    rejectionCauseList: [],
    partListSelection: [],
    partTypeListSelection: [],
    partNumberListSelection: [],
    batchListSelection: [],
    operationTypeListSelection: [],
    operationCategoryListSelection: [],
    userListSelection: [],
    recordTypeListSelection: [],
    rejectionCauseListSelection: [],
    recordIsOk: [],
    declarationSelection: [],
    fromDate: null,
    toDate: null,
    fromMenu: false,
    toMenu: false,
  }),
  computed: {
    canValidate () {
      // eslint-disable-next-line max-len
      if (!this.partNumberListSelection.length && !this.operationTypeListSelection.length && !this.operationCategoryListSelection.length && !this.partListSelection.length && !this.recordTypeListSelection.length) return false
      if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) return false
      return true
    },
    fromDateFormated () {
      return this.formatDate(this.fromDate)
    },
    toDateFormated () {
      return this.formatDate(this.toDate)
    },
    filteredPartNumberList () {
      if (!this.partTypeListSelection.length) return this.partNumberList
      // eslint-disable-next-line max-len
      return this.partNumberList.filter(partNumber => this.partTypeListSelection.includes(partNumber.partType._id))
    },
    filteredBatchList () {
      if (!this.partNumberListSelection.length) return this.batchList
      // eslint-disable-next-line max-len
      return this.batchList.filter(batch => this.partNumberListSelection.includes(batch.partNumber._id))
    },
    filteredOperationTypeList () {
      if (!this.partNumberListSelection.length) return this.operationTypeList
      return this.operationTypeList.filter(operationType => {
        for (const partNumber of this.partNumberListSelection) {
          const tempPartNumber = this.partNumberList.find(pn => pn._id === partNumber)
          // eslint-disable-next-line max-len
          if (tempPartNumber.operations.find(operation => operation.operationType === operationType._id)) return true
        }
        return false
      })
    },
    filteredOperationCategoryList () {
      if (!this.partNumberListSelection.length) return this.operationCategoryList
      // eslint-disable-next-line max-len
      return this.operationCategoryList.filter(operationCategory => operationCategory.operationTypeList.map(opeType => opeType._id).some(r => this.filteredOperationTypeList.map(opeType => opeType._id).includes(r)))
    },
    filteredRecordTypeList () {
      if (!this.partNumberListSelection.length) return this.operationTypeList
      return this.recordTypeList.filter(recordType => {
        for (const partNumber of this.partNumberListSelection) {
          const tempPartNumber = this.partNumberList.find(pn => pn._id === partNumber)
          for (const operation of tempPartNumber.operations) {
            // eslint-disable-next-line max-len
            if (operation.partsRecords.includes(recordType._id)) return true
            // eslint-disable-next-line max-len
            if (operation.batchRecords.includes(recordType._id)) return true
          }
        }
        return false
      })
    },
  },
  async mounted () {
    const [
      partTypeList,
      partNumberList,
      batchList,
      operationTypeList,
      operationCategoryList,
      userList,
      recordTypeList,
      rejectionCauseList,
    ] = await Promise.all([
      Repositories.partType.getList(),
      Repositories.partNumber.getList({ archived: false }),
      Repositories.batch.getList(),
      Repositories.operationType.getList({ archived: false }),
      Repositories.operationCategory.getList(),
      Repositories.user.getList(),
      Repositories.recordType.getList(),
      Repositories.rejectionCause.getList(),
    ])
    this.partTypeList = partTypeList
    this.partNumberList = partNumberList
    this.batchList = batchList
    this.operationTypeList = operationTypeList
    this.operationCategoryList = operationCategoryList
    this.userList = userList
    this.recordTypeList = recordTypeList
    this.rejectionCauseList = rejectionCauseList
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    removePart (item) {
      const index = this.partListSelection.indexOf(item._id)
      if (index >= 0) this.partListSelection.splice(index, 1)
    },
    batchItemText (batch) {
      if (batch.partNumber?.name) {
        return `${batch.number} - ${batch.partNumber.name}`
      }
      return ''
    },
    validate () {
      const filter = {}
      if (this.partTypeListSelection.length) filter.partType = this.partTypeListSelection
      if (this.partNumberListSelection.length) filter.partNumber = this.partNumberListSelection
      if (this.recordTypeListSelection.length) filter.recordType = this.recordTypeListSelection
      // eslint-disable-next-line max-len
      if (this.operationTypeListSelection.length) filter.operationType = this.operationTypeListSelection
      // eslint-disable-next-line max-len
      if (this.operationCategoryListSelection.length) filter.operationCategory = this.operationCategoryListSelection
      if (this.batchListSelection.length) filter.batch = this.batchListSelection
      if (this.userListSelection.length) filter.user = this.userListSelection
      if (this.partListSelection.length) filter.parts = this.partListSelection
      if (this.recordIsOk.length) filter.ok = this.recordIsOk
      if (this.declarationSelection.length) filter.rejectionId = this.declarationSelection
      if (this.fromDate) filter.fromDate = this.fromDate
      if (this.toDate) filter.toDate = this.toDate
      this.$emit('validate', filter)
    },
  },
}
</script>
