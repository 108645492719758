<template>
  <ag-card-container
    title="Export"
    fluid
    no-content-padding
  >
    <v-container class="py-0">
      <filter-selection @validate="fetchData" />
    </v-container>
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import FilterSelection from '../components/FilterSelection'

export default {
  name: 'ExportContainer',
  components: {
    AgCardContainer,
    FilterSelection,
  },
  data: () => ({ }),
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData (filter) {
      try {
        const params = Object.keys(filter).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(filter[k])}`).join('&')
        window.open(`${this.$config.API_URL}/record/export/?token=${this.$store.getters['me/tokens'].accessToken}&${params}`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
    },
  },
}
</script>
